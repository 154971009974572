
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class TimelineEsatJ extends Vue {
    freeTrialTermTitleImage =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/ESAT-J/Group+225+2.png'
    currentTimelineisIpad: boolean = false

    changeCurrentTimelineisIpad() {
        this.currentTimelineisIpad = !this.currentTimelineisIpad
    }
}
