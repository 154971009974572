import Vue from 'vue'
import { MutationTree } from 'vuex'
import { CustomerState, Card } from './state'
import { ActionTypes } from './types'
import { initialState } from '../customer'
import * as Utils from '../../../plugins/utils'

export const mutations: MutationTree<CustomerState> = {
    [ActionTypes.STATE_RESET]: (state: CustomerState) => {
        Object.assign(state, initialState())
    },
    [ActionTypes.NAVIGATE_TO_KIMINI]: (state: CustomerState, payload: any) => {
        window.location.href = payload.data.url
    },
    [ActionTypes.GET_PRODUCTS]: (state: CustomerState, payload: CustomerState) => {
        state.products = payload.products
    },
    [ActionTypes.GET_USERDATA]: (state: CustomerState, payload: CustomerState) => {
        state.userdata = payload.userdata
    },
    [ActionTypes.SELECT_ADDRESS]: (state: CustomerState, payload: { selectedAddress: string }) => {
        state.selectedAddress = payload.selectedAddress
    },
    [ActionTypes.GET_ADDRESSES]: (state: CustomerState, payload: CustomerState) => {
        state.addresses = payload.addresses
        state.selectedAddress = payload.selectedAddress
    },
    [ActionTypes.ADD_ADDRESS]: (state: CustomerState, payload: { address: any }) => {
        state.addresses.push(payload.address)
    },
    [ActionTypes.MODIFY_ADDRESS]: (
        state: CustomerState,
        payload: { addressId: string; address: any }
    ) => {
        const index = state.addresses.findIndex(address => address.id === payload.addressId)
        // state.addresses[index] = payload.address
        // https://vuejs.org/v2/guide/list.html
        // because of limitations of javascript/typescript, it is neccessary to modify the array in a rather awkward matter below to re-render the page.
        Vue.set(state.addresses, index, payload.address)
    },
    [ActionTypes.DELETE_ADDRESS]: (state: CustomerState, payload: { addressId: number }) => {
        // https://vuejs.org/v2/guide/list.html
        // limitations............
        // state.addresses.filter(address => address.id !== payload.addressId)
        const index = state.addresses.findIndex(address => address.id === payload.addressId)
        Vue.delete(state.addresses, index)
    },
    [ActionTypes.GET_CARD_SUCCESS]: (state: CustomerState, payload: Card | null) => {
        state.card = payload
    },
    [ActionTypes.GET_CARD_FAILURE]: (state: CustomerState, payload: Card) => {
        state.card = null
    },
    [ActionTypes.UPDATE_CARD_SUCCESS]: (state: CustomerState, payload: Card) => {
        state.card = payload
    },
    [ActionTypes.START_PAYMENT]: (state: CustomerState) => {
        state.isProcessingPayment = true
    },
    [ActionTypes.REGISTRATION_SUCCESS]: async (state: CustomerState, payload: any) => {
        state.isProcessingPayment = false
        state.orderId = payload.data.order.id
        await Utils.sleep(5)
        window.location.href = payload.data.kimini.first_login_url
    },
    [ActionTypes.REGISTRATION_SUCCESS_ESATJ]: async (state: CustomerState, payload: any) => {
        state.isProcessingPayment = false
        state.orderId = payload.data.order.id
    },
    [ActionTypes.REGISTRATION_FAILURE]: (state: CustomerState) => {
        state.isProcessingPayment = false
    },
    [ActionTypes.SWITCH_PLAN_SUCCESS]: (state: CustomerState, payload: any) => {
        state.userdata.plan = payload.plan.name
        state.isProcessingPayment = false
        state.eventMessage = 'switchPlanSucceeded'
    },
    [ActionTypes.SWITCH_PLAN_FAILTURE]: (state: CustomerState) => {
        state.isProcessingPayment = false
        state.errorMessage = 'switchPlanFailed'
    },
    [ActionTypes.CANCEL_PLAN_SUCCESS]: (state: CustomerState) => {
        state.isProcessingPayment = false
        state.eventMessage = 'cancellationSucceeded'
    },
    [ActionTypes.CANCEL_PLAN_FAILURE]: (state: CustomerState) => {
        state.isProcessingPayment = false
        state.errorMessage = 'cancellationFailed'
    },
    [ActionTypes.HIDE_EVENT_MESSAGE]: (state: CustomerState) => {
        state.eventMessage = undefined
    },
    [ActionTypes.HIDE_ERROR_MESSAGE]: (state: CustomerState) => {
        state.errorMessage = undefined
    },
    [ActionTypes.GET_ORDER_HISTORY_SUCCESS]: (
        state: CustomerState,
        payload: { isAlreadySignedUp: boolean }
    ) => {
        state.isSignedUp = payload.isAlreadySignedUp
    },
    [ActionTypes.GET_ORDER_HISTORY_FAILURE]: (state: CustomerState) => {
        state.isSignedUp = false
    },
    [ActionTypes.SAVE_OPTIONS]: (state: CustomerState, payload: { product: object }) => {
        state.selectedProduct = payload.product
    },
    [ActionTypes.GET_STEP_MAIL]: (state: CustomerState, payload: { data: any }) => {
        state.settingStep = payload.data.data.Items
    },
}
