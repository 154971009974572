import { RootState } from '@/store'
import { CLIENT_KEY } from '@/utils/constants'
import {
    AUTH_CHANGE_PASSWORD,
    AUTH_LOGIN,
    AUTH_SIGN_UP,
    AUTH_FIND_PASSWORD,
    USERMODEL_URL,
    STORE_API_URL,
    TEST_ACCOUNT,
} from '@/utils/urls'
import Axios from 'axios'
import { ActionContext, ActionTree } from 'vuex'
import { AuthenticationState } from './state'
import { ActionTypes } from './types'
import { AdminTestState } from './admin/state'

export const actions: ActionTree<AuthenticationState, RootState> = {
    [ActionTypes.LOGIN_REQUEST]: async (
        context: ActionContext<AuthenticationState, RootState>,
        payload: { email: string; password: string }
    ): Promise<AuthenticationState> => {
        const auth = {} as AuthenticationState
        auth.email = payload.email

        const formData = new FormData()
        formData.set('email', payload.email)
        formData.set('password', payload.password)
        // TODO check why the client key is required and how it's used on the backend.
        formData.set('client_key', CLIENT_KEY)
        const res = await Axios.post(USERMODEL_URL + AUTH_LOGIN, formData)
        if (res.data.status) {
            auth.token = res.data.token
            auth.eventMessage = 'login'
            auth.errorMessage = undefined
            auth.name = res.data.data?.name
            context.commit(ActionTypes.LOGIN_SUCCESS, auth)
        } else {
            auth.token = ''
            auth.eventMessage = undefined
            auth.errorMessage = 'invalidCredentials'
            auth.name = ''
            context.commit(ActionTypes.LOGIN_FAILURE, auth)
        }
        return auth
    },
    [ActionTypes.LOGOUT_REQUEST]: (
        context: ActionContext<AuthenticationState, RootState>
    ): void => {
        context.commit(ActionTypes.LOGOUT_SUCCESS)
    },
    [ActionTypes.SIGNUP_REQUEST]: async (
        context: ActionContext<AuthenticationState, RootState>,
        payload: {
            email: string
            password: string
            firstName: string
            lastName: string
            birthday: string
        }
    ): Promise<AuthenticationState> => {
        const auth = {} as AuthenticationState
        auth.email = payload.email
        auth.name = payload.firstName + ' ' + payload.lastName

        const formData = new FormData()
        formData.set('email', payload.email)
        formData.set('password', payload.password)
        formData.set('first_name', payload.firstName)
        formData.set('last_name', payload.lastName)
        formData.set('birthday', payload.birthday)
        // TODO check why the client key is required and how it's used on the backend.
        formData.set('client_key', CLIENT_KEY)
        const res = await Axios.post(USERMODEL_URL + AUTH_SIGN_UP, formData)
        if (res.data.status) {
            auth.token = res.data.token
            auth.errorMessage = undefined
            context.commit(ActionTypes.SIGNUP_SUCCESS, auth)
        } else if (res.data.code === 2002) {
            auth.token = ''
            auth.errorMessage = 'emailTaken'
            context.commit(ActionTypes.SIGNUP_FAILURE, auth)
        } else {
            auth.token = ''
            auth.errorMessage = 'signupError'
            context.commit(ActionTypes.SIGNUP_FAILURE, auth)
        }
        return auth
    },
    [ActionTypes.CHANGE_PASSWORD_REQUEST]: (
        context: ActionContext<AuthenticationState, RootState>,
        payload: { password: string }
    ): void => {
        const formData = new FormData()
        formData.set('password', payload.password)
        Axios.post(USERMODEL_URL + AUTH_CHANGE_PASSWORD, formData, {
            headers: { Authorization: `Bearer ${context.rootState.authentication.token}` },
        })
            .then(res => context.commit(ActionTypes.CHANGE_PASSWORD_SUCCESS))
            .catch(err => context.commit(ActionTypes.CHANGE_PASSWORD_FAILURE))
    },

    [ActionTypes.FIND_PASSWORD_REQUEST]: async (
        context: ActionContext<AuthenticationState, RootState>,
        payload: { email: string; key: string }
    ): Promise<boolean> => {
        const formData = new FormData()
        formData.set('email', payload.email)
        formData.set('key', payload.key)
        formData.set('language', 'jp')
        try {
            const res = await Axios.post(USERMODEL_URL + AUTH_FIND_PASSWORD, formData)
            if (res.data.status) {
                context.commit(ActionTypes.FIND_PASSWORD_SUCCESS)
                return true
            }
            context.commit(ActionTypes.SEND_MAIL_FAILURE)
            return false
        } catch (e) {
            context.commit(ActionTypes.SEND_MAIL_FAILURE)
            return false
        }
    },
    [ActionTypes.MAKE_TEST_ACCOUNT]: async (
        context: ActionContext<AdminTestState, RootState>,
        payload: {
            email: string
            password: string
            firstName: string
            lastName: string
            birthday: string
            stage: string
        }
    ): Promise<void> => {
        const auth = {} as AdminTestState

        const formData = new FormData()
        formData.set('email', payload.email)
        formData.set('password', payload.password)
        formData.set('first_name', payload.firstName)
        formData.set('last_name', payload.lastName)
        formData.set('birthday', payload.birthday)
        formData.set('client_key', CLIENT_KEY)

        const res = await Axios.post(USERMODEL_URL + AUTH_SIGN_UP, formData)

        if (res.data.status) {
            auth.token = res.data.token
            await Axios.post(
                STORE_API_URL + TEST_ACCOUNT,
                { stage: payload.stage },
                {
                    headers: { Authorization: 'Bearer ' + auth.token },
                }
            )
                .then(() => {
                    console.log('done')
                    alert('アカウントの作成が完了しました')
                })
                .catch(err => {
                    console.error('Error', err)
                    return false
                })
        }
    },
}
