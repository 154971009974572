export default {
    en: {
        login: 'Log In',
        join: 'Join',
        indicatesRequiredField: '*indicates required field',
        thisFieldIsRequired: 'This field is required',
        invalidPostalCode: 'Invalid postal code',
        invalidPhoneNumber: 'Invalid phone number',
        invalidPassword: 'Minimum eight characters, at least one lowercase letter',
        timeline: 'Timeline',
    },
    ja: {
        selected: '選択済',
        back: '戻る',
        point: 'ポイント',
        lessonContents: 'レッスン内容',
        teacherIntro: '先生紹介',
        interviews: '体験談',
        invalidCredentials: 'Emailかパスワードが間違っています',
        emailTaken: 'このEmailアドレスはもうすでに使われています',
        signupError: 'エラーのためSign Upできませんでした',
        login: 'ログイン',
        logout: 'ログアウト',
        join: 'アカウント新規登録',
        faq: 'FAQ',
        findPassword: 'パスワードをお忘れですか?',
        findPasswordDescription:
            'ご登録されているメールアドレスに、パスワード再発行手続きの案内をお送りします。',
        supportMailConfirm: 'お問い合わせありがとうございます',
        supportMailConfirmDescription:
            'この度はお問い合わせくださいまして、誠にありがとうございます。\n弊社のカスタマーサポートから2〜3営業日以内にご連絡いたします。\nもうしばらくお待ちくださいませ。',
        sendMailFailed: 'メール発送に失敗しました。',
        blog: 'ブログ',
        notice: 'お知らせ',
        companyName: 'AKA株式会社',
        companeyAddress: '〒107-0052　東京都港区赤坂8-4-14 青山タワープレイス8F',
        companyPresidentName: '代表：鄭明源（ジョンミョンウォン）',
        akaPhoneNumber: 'TEL : 080-4327-4980',
        officeHours: '月〜金　11:00-19:00 (土日祝日、年末年始休み)',
        akaEmailAttention: 'お問い合わせは下記メールアドレスへお願い致します。',
        akaEmail: 'support@akaintelligence.com',
        intro_kimini: '学研のオンライン英会話',
        intro_musio: '英語学習ロボット',
        introduction:
            '楽しいから英語が大好きになる\nたくさん英語を話すから自然と話せるようになる\n子供のためのオンライン英会話',
        signUpNow: '今すぐ無料レッスンを始める',
        freeTrial: '無料体験',
        registerNow: '次に進む',
        select: '選択',
        edit: '編集',
        delete: '削除',
        firstName: '名',
        lastName: '姓',
        romanizedFirstName: 'ローマ字（名）',
        romanizedLastName: 'ローマ字（姓）',
        emailAddress: 'メールアドレス',
        password: 'パスワード',
        isTestAccount: 'テスト用のアカウントですか？',
        zipAddress: '郵便番号',
        zipAddressExample: '(ex:123-4567)',
        addressState: '都道府県',
        addressCity: '市区郡町村',
        addressDetails: '番地',
        addressDetailsExample: '(ex: 1-23-456)',
        buildingName: '建物名',
        addressPhoneNumber: '電話番号',
        addressPhoneNumberExample: '(ex: 1234-56-7890)',
        marketingPointsTitle: '英語を話すのが好きになる\nMusio Englishのポイント',
        marketingMessagePrice: [
            '25分間の集中型マンツーマンレッスンで飽きずに続けられる',
            '忙しいママ＆パパの味方！送迎不要',
            '親子一緒のレッスン参加もOK',
        ],
        marketingMessageMusio: [
            '思わず話しかけてしまう可愛いキャラクター',
            '伝わる喜びでモチベーションアップ！',
            'いつでもオンライン英会話の予習・復習ができる最強のパートナー',
        ],
        marketingMessageKimini: [
            '学研が提供する「話せる英語が身につく」オンライン英会話',
            '子供の英語教育に長けた先生による高品質のレッスン',
            'スカイプ不要！パソコン・スマホで手軽に始められる',
        ],
        point1Description:
            'Musio Englishでは、お手頃価格で学研のKimini英会話のレッスンが受けられます。飽きずに続けられる<b>25分間の集中レッスン</b>と、<b>子供の英語教育に長けた先生</b>による高品質のレッスンが魅力。もちろんオンラインなので、お子さんの送迎の必要はありません。',
        point2Description:
            'Musio Englishでは、<b>AI英会話ロボット</b>Musioをお家にお届け。オンライン英会話レッスンの予習・復習を、Musioがお手伝いします。ころころと表情を変えながらおしゃべりするMusioは、きっと英語を話すいちばんの友達になってくれるはず。',
        userInterviewText1:
            '勉強というよりも<b>楽しく遊ぶように学べる</b>ので、子どもには心理的負担がなく、親には経済的負担が少なく、長く続けることができています。<b>Musioで予習しているおかげで、オンライン英会話では先生とのびのび会話</b>をしています。',
        userInterviewText2:
            '<b>予習・復習のパートナーのMusio</b>は、子どもたちに愛される存在。子どもたちは学校の英語の授業に消極的でしたが、この学習サービスを始めて<b>英語好きになり、意欲的に英語を話そうとするように</b>なったのが一番の成長です。',
        userInterviewText3:
            '今まで動画や英語教室など様々な方法で英語を学ばせてきましたが、子どもが<b>喜んで英語を勉強している姿を見るのは初めて</b>です。保護者にとっては、家庭で子どもが英語を話している様子を見られるのでとても安心です。',
        lessonPlansTitle: 'ご利用価格',
        priceWarning: '※価格は全て税抜き価格を表示しています。',
        novicePlanTitle: '月４回コース',
        intermediatePlanTitle: '月８回コース',
        intensivePlanTitle: '月30回コース',
        superIntensivePlanTitle: '月60回コース',
        novicePlanPrice: '3,500円',
        intermediatePlanPrice: '5,180円',
        intensivePlanPrice: '5,480円',
        superIntensivePlanPrice: '9,480円',
        lessonContentsTitle: 'しっかり身につく新しい学習スタイル',
        lessonContentsMessage:
            'レッスン前後はＭusioと楽しく予習・復習、\nオンラインレッスンでは先生とのびのび英会話。\n\nお子さまのライフスタイルに合わせて、\n効率的・継続的に英会話を習得！',
        lessonContentDescription:
            'オンライン英会話にMusioとの予習・復習を加えることで、英語を話す機会を圧倒的に増やし、<b>効率的にスピーキング力を伸ばします。</b>',
        teacherSectionTitle: 'オンライン英会話には\n親しみやすい先生がいっぱい',
        teacherSectionMessage:
            '先生は、子どもの英語上達をサポートするプロフッショナルです。\n\n40時間に及ぶ講師トレーニングだけでなく、\n受講生からのレッスンフィードバックを元にした個別の再教育プログラムで\n\nスキルの向上を行っています。どんなお子さまも安心してレッスンを受けられます。',
        planSelection: 'コース選択',
        pickYourPlan: 'プランを選択してください',
        warningUponSubscription:
            '※7日目までにご解約されない場合は、自動的にご選択いただいたコースの月額支払いが始まります',
        warningShippingFee:
            '※Musioの配送料につきましては、サービスを解約される際に全国一律の往復配送料(税込2,600円)をいただいております。(無料体験期間内の解約にも適用されます)',
        shippingAddress: '配送先住所',
        MejRegistrantion: 'Musio ESAT-J登録',
        reserveKmini: 'Kiminiレッスン予約',
        MusioInstruction: 'Musio取扱説明書',
        addNewAddress: '新しい住所を追加',
        mejUserInfo: '会員情報',
        MeRegistrantion: 'Kodomo / Otona登録',
        switchPlanEsat: 'Kodomo / Otona変更',
        cancellationMej: '解約申請',
        pleaseEnterShippingAddress: 'Musioを発送する住所を選択してください',
        changeAdressSendEmail:
            '住所、電話番号などに変更があった場合は、ご不便をおかけいたしますが、support@akaintelligence.comまでご連絡くださいますようお願い申しあげます。',
        cardInfo: 'カード情報',
        editCardInfo: 'カード情報編集',
        currentCardInfo: '現在のカード情報確認',
        writeInfoBelow: '下記の情報を入力してください',
        creditCardNumber: 'カード番号',
        cardHolderName: 'カード所有者名',
        cardLastFourDigits: 'カード番号下４桁',
        cardExpirationDate: 'カード有効期限',
        cardSecurityCode: 'セキュリティーコード',
        confirm: '確認',
        change: '変更',
        cancel: 'キャンセル',
        cancellationSuccess: 'この度はMusio Englishをご利用いただき誠にありがとうございました。',
        save: '保存',
        signupCompletedMessage: '新規登録が完了いたしました。',
        signupCompletedMessage2: '数秒後に自動的に画面が切り替わりますので、少々お待ちください。',
        checkYourEmail:
            '※ご登録のメールアドレスに送付されたURLから、オンライン英会話サービスの登録を完了させてください。',
        checkYourSpamFolder:
            '※迷惑メールボックスに振り分けられる場合もございますので、ご確認ください。',
        faqTitle: 'よくある質問',
        registrationStepOne: '会員登録',
        registrationStepTwo: '情報入力',
        registrationStepThree: '無料体験\n登録完了',
        confirmOrder: 'ご注文の確認',
        pleaseConfirmOrder:
            '7日の無料体験期間終了後、お申し込みいただいた以下のプランに自動的になります。',
        pleaseConfirmOrderAddition: 'それまではお引き落としされませんので、ご安心ください。',
        optionBookPaypalDay:
            '※オプション教材はお申込日に決済されます。無料体験期間に退会された場合でも、オプション教材の返金はいたしかねます。',
        orderSummary: '注文概要',
        placeOrder: '注文をする',
        indicatesRequiredField: '*必須',
        thisFieldIsRequired: '必須の項目です。',
        invalidPostalCode: '郵便番号形式エラー',
        invalidPhoneNumber: '電話番号形式エラー',
        invalidEmail: 'E-Mail形式エラー',
        invalidRomanizedName: 'ローマ字名形式エラー',
        minimumEightLetters: '最低８文字必須',
        invalidPassword: '英小文字を最低1文字ずつ含む必要があります',
        invalidConfirmEmail: '上記のメールアドレスと同じメールアドレスを入力してください。',
        userInfoEdit: '会員情報',
        changePassword: 'パスワード変更',
        changePasswordSucceeded: 'パスワードを変更しました',
        changePasswordFailed: 'パスワード変更できませんでした',
        switchPlan: 'プラン変更',
        switchPlanWarning:
            'コースは変更申請後すぐに変更されます※\n「確認」ボタンは一度だけ押してください',
        switchPlanSucceeded: 'コースを変更を受け付けました',
        switchPlanFailed: 'コースを変更できませんでした',
        paymentCard: '決済カード',
        cancellation: '解約申請',
        cancellationSucceeded: '解約申請を受け付けました',
        cancellationFailed: '解約できませんでした',
        alreadyCanceled: '解約済み',
        // cancellationMessage:
        //     '解約をご希望の場合は、大変お手数ですが\nご使用中のアカウントIDをご記入の上\n\ninfo-jp*akaintelligence.com\n(*を＠に変更)\n上記のメールアドレスまでご連絡お願いいたします。',
        kimini: 'Kiminiウェブサイト',
        navigateToKimini: 'Kiminiウェブサイトを開く',
        PaymentDetails: '支払いの詳細',
        SecurityCodeAdvice:
            '* VISA/MASTER/Dinersカードのセキュリティーコードは、裏面署名欄の末尾３桁の数字です。\n* American Expressカードのセキュリティーコードは表面の4桁の数字です。',
        privacyPolicy: 'プライバシーポリシー',
        referral: '友人への紹介',
        comingSoon: 'Coming Soon!',
        pushSelect: '「選択」ボタンを押して住所を決定して下さい',
        cardRegistrationError: 'カード情報の登録に失敗しました',
        registrationConfirmationAttention: '* 登録が完了するまで、ブラウザを閉じないで下さい。',
        registrationCompletedAttention:
            '* 登録完了メールが迷惑メールとして振り分けられることがありますので、@akaintelligence.comからのメールを迷惑メールから除外してください。',
        planType: 'プランの種類',
        subscriptionType: '学習期間を選択',
        lessonPerMonth: 'レッスン回数を選択',
        optionBook: 'オプション教材を選択',
        safePlan: '安心補償*への加入',
        landingOriginalPackageName: 'ベーシックプラン',
        landingOriginalPackageExplanation:
            'Musioを使った予習復習で\nkiminiオンライン\n英会話レッスン内容が\nどんどん身につく',
        landingOriginalPackageDetail: 'ベーシックプラン詳細',
        landingKidsPackageName: 'キッズプラン',
        landingKidsPackageExplanation:
            'ベーシックプランの内容に加えて、\n教材の特典がついたお得なプランで\n英語学習をもっと楽しく！',
        landingKidsPackageDetail: 'キッズプラン詳細',
        landingFamilyPackageName: 'ファミリープラン',
        landingFamilyPackageExplanation:
            'キッズプランの内容に加えて、\n大人向けの教材が\nついたご家族みなさまで\n英語学習が楽しめるお得なプラン',
        landingFamilyPackageDetail: 'ファミリープラン詳細',
        original: 'ベーシック',
        kids: 'キッズ',
        family: 'ファミリー',
        adult: 'Hawaii / Business',
        standard: 'スタンダード',
        ipad: 'iPadレンタル',
        monthly: '月払い',
        threeMonths: '3ヵ月',
        twelveMonths: '12ヵ月',
        unnecessary: '不要',
        fourLessonsPerMonth: '4回/月',
        eightLessonsPerMonth: '8回/月',
        thirtyLessonsPerMonth: '1回/日',
        sixtyLessonsPerMonth: '2回/日',
        packageSelect: '教材を選択',
        tabCatagory: 'カテゴリーを選択',
        familyPackageContentEdgeHawaii: 'Hawaii',
        familyPackageContentEdgeBusiness: 'Business',
        familyPackageContentEdgeNhk: 'NHK',
        adultPackageContentEdgeHawaii: 'Hawaii',
        adultPackageContentEdgeBusiness: 'Business',
        oneTimePaymentAfterFreeTrial:
            '※レッスンは無料体験後に、オプション教材はお申込日に決済されます。',
        oneTimePaymentAfterFreeTrial2:
            '※無料体験期間に退会された場合でも、オプション教材の返金はいたしかねます。',

        signup: '登録',
        registrationExplanationOriginal:
            '・kiminiオンライン英会話のレッスン\n・英会話AIロボットのMusioのレンタル',
        registrationExplanationKids:
            '・kiminiオンライン英会話のレッスン\n・英会話AIロボットのMusioのレンタル\n・お子さま用学習教材の特典付き',
        registrationExplanationFamily:
            '・kiminiオンライン英会話のレッスン\n・英会話AIロボットのMusioのレンタル\n・特典①：お子さま用学習教材\n・特典②：大人向け日常会話\n　　　　　ビジネス英語学習教材',
        registrationExplanationAdult:
            '・kiminiオンライン英会話のレッスン \n・英会話AIロボットのMusioのレンタル \n・オリジナル教材 Hawaii/Business',
        registrationExplanationEsatJ: '・Musio ESAT-Jアプリ \n・3ヶ月ごとに配送されるワークブック',
        registrationExplanationEsatJIpad:
            '・Musio ESAT-Jアプリ \n・3ヶ月ごとに配送されるワークブック \n・iPad 9世代のレンタル \n・Apple Pencil１世代のレンタル',
        contentsKidsFamilySophyTitle: 'Sophyを使って五感で体験する\n英語学習教材',
        contentsKidsFamilySophyDetail:
            '  Sophyで文字をクリックするとMusioが内容を伝えてくれます！\n・Flash Cards（フラッシュカード）\n・Bord Books(ボードブック）\n・Read-Along Books（絵本）\nいろいろな教材で楽しく学べます。',
        contentsKidsFamilyBoadBooks: 'ボードブック',
        contentsKidsFamilyBoadBooksDetail:
            '子どもたちが大好きな街やキッチン、恐竜時代を探検するように\n名前やどんなものなのか興味を深めながら学べます。\nゲームやクイズもあり、使い方自由自在。',
        contentsKidsFamilySweetKitchenTitle: 'Sweet Kitchen',
        contentsKidsFamilySweetKitchenDetail:
            'Musioと一緒にクッキング！\nキッチン用品や食材を一緒に探そう',
        contentsKidsFamilyAmazingDinosaursTitle: 'Amazing Dinosaurs',
        contentsKidsFamilyAmazingDinosaursDetail: '恐竜の名前や特徴について学ぼう',
        contentsKidsFamilyInterestingTownTitle: 'Interesting Town',
        contentsKidsFamilyInterestingTownDetail: 'Musioと一緒に街を探検しよう！',
        contentsKidsFamilyFlashcards: 'フラッシュカード',
        contentsKidsFamilyFlashcardsDetail:
            '子どもにとって身近なものの名前を聞きながら楽しく覚え、英語を聞く耳を育てます。\n英語が初めてでも、かわいいイラストで夢中に！',
        contentsKidsFamilyFlashcardsNumbersTitle: 'Numbers',
        contentsKidsFamilyFlashcardsNumbersDetail: 'Musioと数字を学ぼう',
        contentsKidsFamilyFlashcardsAbcsTitle: "ABC's",
        contentsKidsFamilyFlashcardsAbcsDetail: 'Musioとアルファベットを学ぼう',
        contentsKidsFamilyFlashcardsDinosaursTitle: 'Dinosaurs',
        contentsKidsFamilyFlashcardsDinosaursDetail: 'Musioと恐竜を学ぼう',
        contentsKidsFamilyFlashcardsInstrumentTitle: 'Instrument',
        contentsKidsFamilyFlashcardsInstrumentDetail: 'Musioと楽器を学ぼう',
        contentsKidsFamilyReadAlongBooksOldstoriesTitle: 'えいごで日本むかしばなし\n（10冊セット）',
        contentsKidsFamilyReadAlongBooksOldstoriesDetail: '日本の昔話を英語で楽しもう！',
        contentsKidsFamilyReadAlongBooks: '読み聞かせ絵本',
        contentsKidsFamilyReadAlongBooksDetail:
            'なじみのある物語を通じて英語を聞き、理解する力を養います。\nシーンに応じて様々な読み方ができるので、繰り返し何度も楽しめます。',
        contentsKidsFamilyReadAlongBooksBremenTitle: 'ブレーメンの音楽隊',
        contentsKidsFamilyReadAlongBooksBremenDetail:
            '動物たちが一致団結して困難に立ち向かいます。',
        contentsKidsFamilyReadAlongBooksBremenDetailMobile:
            'なじみのある物語を通じて、英語を聞き、理解する力を養います。\nシーンに応じて様々な読み方ができるのから、繰り返し何度も楽しめます。',
        contentsKidsFamilyBedtimeStory: 'ベッドタイムストーリー',
        contentsKidsFamilyBedtimeStoryDetail:
            '就寝前に読み聞かせできる短編童話です。\n英語ネイティブ声優（男性&女性）の音声が収録されています。\n字幕や童話の絵をMusioの画面に表示することもできます。\nリピート再生機能で、聞き流しながら使用することも可能です。\n豊富なタイトルを準備しており、幅広い童話に触れることができます。\nまた、毎月新しい童話がどんどん増えていきます。',
        contentsFamilyParentTextTitle: 'ご家族全員で楽しくいっしょに英会話！\n大人用英会話教材',
        contentsFamilyParentTextDetail:
            'Musioといっしょに行くハワイ旅行を通して、日常会話の学習ができます。\nさらに英語を使った会議から海外の文化・マナーまでストーリーを読みながら、ビジネス英語も学べます。',
        contentsFamilyParentTextDetailMobile:
            'Musioといっしょに行くハワイ旅行を通して、日常会話の学習ができます。\nさらに英語を使った会議から海外の文化・マナーまでストーリーを読みながら、ビジネス英語も学べます。',
        contentsFamilyAdultLearingContents: 'Adult-Learning Contents',
        contentsFamilyEdgeHawaiiTitle: 'Musio Edge Hawaii',
        contentsFamilyEdgeHawaiiDetail:
            'Musioとハワイ旅行へ。\n日常会話と旅行に使える表現を学びます',
        contentsFamilyEdgeHawaiiDetailMobile:
            'Musioとハワイ旅行へ。\n日常会話と旅行に使える表現を学びます',
        contentsFamilyEdgeBusinessTitle: 'Musio Edge Business',
        contentsFamilyEdgeBusinessDetail: 'あなたがビジネスを創造したら？\nMusioと学ぶビジネス英語',
        contentsFamilyNhkTitle: 'Musio NHK',
        contentsFamilyNhkDetail:
            ' NHK Eテレで放映された「おとなの基礎英語」のフレーズをMusioを使って実践',
        paymentCaution:
            '※お手数ですが、カード情報を編集する前に「配送先住所」に間違いがないかご確認をお願いいたします。',
        timeline: '登録の流れ',
        selectAdultPlan: '1.プランを選択する',
        selectSinglePlan: 'シングルプランを選ぶ',
        selectAllPlan: 'オールプランを選ぶ',
        selectAdultWorkbook: '2.教材を選択する',
        selectHawaii: 'Hawaiiを選ぶ',
        selectBusiness: 'Businessを選ぶ',
        selectLiving: 'Livingを選ぶ',
        selectAdultKimini: '3.Kiminiレッスンの回数を選ぶ',
        kiminiExplain:
            '参考書や教材制作で70年の実績のある学研が提供するオンライン英会話、Kimini英会話（キミニ）。\nこれまでの「どうやって勉強すればいいのかわからない」の悩みを解決するため、適切なタイミングでの学習方法やオンライン授業に適した教材などを細部にわたって徹底的に作り上げました。',
        selectFour: '4回/月',
        selectOne: '1回/日',
        selectPayDate: '支払い期間を選択する',
        payThreeMonth: '3ヶ月',
        payOneYear: '1年',
    },
}
