// usermodel apis
export const USERMODEL_URL = 'https://usermodel.themusio.com'
export const AUTH_SIGN_UP = '/auth/signup'
export const AUTH_LOGIN = '/auth/signin'
export const AUTH_CHANGE_PASSWORD = '/auth/password/change'
export const AUTH_FIND_PASSWORD = '/auth/password/reset/request'

// store apis
export const STORE_API_URL =
    process.env.NODE_ENV === 'production'
        ? 'https://store-api.themusio.com/v2'
        : process.env.NODE_ENV === 'test'
        ? 'https://kvcfl1rkra.execute-api.ap-northeast-1.amazonaws.com/api/' // store-dev
        : 'http://127.0.0.1:8000'
export const STORE_REST_PRODUCTS = '/rest/products'
export const STORE_REST_GET_USERDATA = '/rest/musioenglish/userdata'
export const STORE_REST_CHARGE = '/rest/musioenglish/payment'
export const STORE_REST_SWITCH = '/rest/musioenglish/switch'
export const STORE_REST_CANCEL = '/rest/musioenglish/cancel'
export const STORE_REST_ADDRESS = '/rest/address'
export const STORE_REST_ADDRESS_SELECT = '/rest/address/select'
export const STORE_REST_CARD = '/rest/musioenglish/card'
export const STORE_REST_NAVIGATE_TO_KIMINI = '/rest/musioenglish/navigate/kimini'
export const STORE_ORDER_HISTORY = '/rest/musioenglish/order-history'
export const STORE_SUPPORT_MAIL = '/rest/musioenglish/support-mail'
export const STORE_ADMIN_MAIL = '/rest/musioenglish/sendMail'
export const STORE_ADMIN_STEP_MAIL = '/rest/musioenglish/stepMail'
export const STORE_NOTIFICATION = '/rest/musioenglish/notification'
export const TEST_ACCOUNT = '/rest/musioenglish/createtestuser'
