
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class ChooseMultiPlanTabKids extends Vue {
    @Prop() title!: string
    @Prop({
        default: { color: '#087426', background: '#E2FFDD' },
    })
    selectedTabColor!: {
        background: string
        color: string
    }
    selectedTabs: string[] = ['選択しない']

    @Prop() tabValue!: string
    tabValueLocal: string = ''

    @Emit('returnTabValue')
    emitReturnObtionBook() {
        if (this.selectedTabs.length === 2) {
            return 'NEW CROWN Connect'
        } else if (this.selectedTabs.includes('NEW CROWN')) {
            return 'NEW CROWN'
        } else if (this.selectedTabs.includes('Connect')) {
            return 'Connect'
        } else {
            return '選択しない'
        }
    }

    isChecked(name: string) {
        return this.selectedTabs.includes(name)
    }

    toggleChecked(name: string) {
        if (name === '選択しない') {
            this.selectedTabs = [name]
        } else {
            if (this.selectedTabs.includes(name)) {
                this.selectedTabs = this.selectedTabs.filter(item => item !== name)
            } else {
                if (this.selectedTabs.includes('選択しない')) {
                    this.selectedTabs = [name]
                } else {
                    this.selectedTabs.push(name)
                }
            }
        }
            this.emitReturnObtionBook()   
    }
}
