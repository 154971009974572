import { RootState } from '@/store'
import {
    STORE_REST_CANCEL,
    STORE_REST_SWITCH,
    STORE_API_URL,
    STORE_REST_ADDRESS,
    STORE_REST_ADDRESS_SELECT,
    STORE_REST_CARD,
    STORE_REST_CHARGE,
    STORE_REST_PRODUCTS,
    STORE_REST_NAVIGATE_TO_KIMINI,
    STORE_ORDER_HISTORY,
    STORE_REST_GET_USERDATA,
    STORE_SUPPORT_MAIL,
    STORE_ADMIN_MAIL,
    STORE_ADMIN_STEP_MAIL,
} from '@/utils/urls'
import Axios from 'axios'
import { ActionContext, ActionTree } from 'vuex'
import { Card, CustomerState } from './state'
import { ActionTypes } from './types'
import { ActionTypes as authentication } from '../authentication/types'
import { HSFormData, submit } from './submit_to_form'

export const actions: ActionTree<CustomerState, RootState> = {
    [ActionTypes.NAVIGATE_TO_KIMINI]: (context: ActionContext<CustomerState, RootState>): void => {
        Axios.get(STORE_API_URL + STORE_REST_NAVIGATE_TO_KIMINI, {
            headers: { Authorization: 'Bearer ' + context.rootState.authentication.token },
        }).then(res => {
            context.commit(ActionTypes.NAVIGATE_TO_KIMINI, res)
        })
    },
    [ActionTypes.GET_PRODUCTS]: async (
        context: ActionContext<CustomerState, RootState>
    ): Promise<void> => {
        const payload = await fetch(STORE_API_URL + STORE_REST_PRODUCTS + '?category=musioenglish')
        const res = await payload.json()
        context.commit(ActionTypes.GET_PRODUCTS, { products: res.data })
        console.log('api : ', STORE_API_URL)
    },
    [ActionTypes.GET_USERDATA]: async (
        context: ActionContext<CustomerState, RootState>
    ): Promise<void> => {
        try {
            const payload = await fetch(STORE_API_URL + STORE_REST_GET_USERDATA, {
                headers: { Authorization: 'Bearer ' + context.rootState.authentication.token },
            })
            const res = await payload.json()
            context.commit(ActionTypes.GET_USERDATA, { userdata: res.data })
        } catch (e) {
            context.commit(ActionTypes.GET_USERDATA, { userdata: null })
        }
    },
    [ActionTypes.SELECT_ADDRESS]: (
        context: ActionContext<CustomerState, RootState>,
        payload: { id: string }
    ): void => {
        Axios.post(STORE_API_URL + STORE_REST_ADDRESS_SELECT, payload, {
            headers: { Authorization: 'Bearer ' + context.rootState.authentication.token },
        }).then(() => {
            context.commit(ActionTypes.SELECT_ADDRESS, { selectedAddress: payload.id })
        })
    },
    [ActionTypes.GET_ADDRESSES]: (context: ActionContext<CustomerState, RootState>): void => {
        Axios.get(STORE_API_URL + STORE_REST_ADDRESS, {
            headers: { Authorization: 'Bearer ' + context.rootState.authentication.token },
        }).then(res => {
            context.commit(ActionTypes.GET_ADDRESSES, {
                addresses: res.data.data,
                selectedAddress: res.data.selected_address,
            })
        })
    },
    [ActionTypes.ADD_ADDRESS]: (
        context: ActionContext<CustomerState, RootState>,
        payload: any
    ): void => {
        Axios.post(STORE_API_URL + STORE_REST_ADDRESS, payload, {
            headers: { Authorization: 'Bearer ' + context.rootState.authentication.token },
        }).then(res => {
            context.commit(ActionTypes.ADD_ADDRESS, { address: res.data.data })
        })
    },
    [ActionTypes.MODIFY_ADDRESS]: (
        context: ActionContext<CustomerState, RootState>,
        payload: any
    ): void => {
        Axios.put(STORE_API_URL + STORE_REST_ADDRESS + '/' + payload.id, payload, {
            headers: { Authorization: 'Bearer ' + context.rootState.authentication.token },
        }).then(res => {
            context.commit(ActionTypes.MODIFY_ADDRESS, {
                addressId: payload.id,
                address: res.data.data,
            })
        })
    },
    [ActionTypes.DELETE_ADDRESS]: (
        context: ActionContext<CustomerState, RootState>,
        payload: { id: string }
    ): void => {
        Axios.delete(STORE_API_URL + STORE_REST_ADDRESS + '/' + payload.id, {
            headers: { Authorization: 'Bearer ' + context.rootState.authentication.token },
        }).then(res => {
            context.commit(ActionTypes.DELETE_ADDRESS, { addressId: payload.id })
        })
    },
    [ActionTypes.GET_CARD_REQUEST]: async (
        context: ActionContext<CustomerState, RootState>
    ): Promise<Boolean> => {
        try {
            const res = await Axios.get(STORE_API_URL + STORE_REST_CARD, {
                headers: { Authorization: 'Bearer ' + context.rootState.authentication.token },
            })
            const cardHolder = res.data?.data?.card_name
            const cardNumber = res.data?.data?.card_number
            const expirationDate = res.data?.data?.card_exp
            const brand = res.data?.data?.card_brand
            if (cardNumber && expirationDate) {
                context.commit(ActionTypes.GET_CARD_SUCCESS, {
                    cardHolder: cardHolder,
                    cardNumber: cardNumber,
                    expirationDate: expirationDate,
                    brand: brand,
                } as Card)
            } else {
                context.commit(ActionTypes.GET_CARD_SUCCESS, null)
            }
            return true
        } catch (e) {
            context.commit(ActionTypes.GET_CARD_FAILURE)
            return false
        }
    },
    [ActionTypes.UPDATE_CARD_REQUEST]: async (
        context: ActionContext<CustomerState, RootState>,
        payload: { token: string }
    ): Promise<Boolean> => {
        try {
            const res = await Axios.post(STORE_API_URL + STORE_REST_CARD, payload, {
                headers: { Authorization: 'Bearer ' + context.rootState.authentication.token },
            })
            context.commit(ActionTypes.UPDATE_CARD_SUCCESS, {
                cardHolder: res.data.data.card_name,
                cardNumber: res.data.data.card_number,
                expirationDate: res.data.data.card_exp,
                brand: res.data.data.card_brand,
            } as Card)
            return true
        } catch (e) {
            return false
        }
    },
    [ActionTypes.REGISTRATION_REQUEST]: async (
        context: ActionContext<CustomerState, RootState>,
        payload: { productId: string; option: object; userIdentifier: string; isESATJ: boolean }
    ): Promise<boolean> => {
        try {
            const res = await Axios.post(STORE_API_URL + STORE_REST_CHARGE, payload, {
                headers: {
                    Authorization: 'Bearer ' + context.rootState.authentication.token,
                },
            })
            submit((res.data as unknown) as HSFormData)
            if (payload.isESATJ) {
                context.commit(ActionTypes.REGISTRATION_SUCCESS_ESATJ, res)
            } else {
                context.commit(ActionTypes.REGISTRATION_SUCCESS, res)
            }

            return true
        } catch (err) {
            context.commit(ActionTypes.REGISTRATION_FAILURE)
            return false
        }
    },
    [ActionTypes.SWITCH_PLAN_REQUEST]: async (
        context: ActionContext<CustomerState, RootState>,
        payload: { productId: string; option: object }
    ): Promise<void> => {
        try {
            const res = await fetch(STORE_API_URL + STORE_REST_SWITCH, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + context.rootState.authentication.token,
                },
                body: JSON.stringify(payload),
            })
            const data = await res.json()
            context.commit(ActionTypes.SWITCH_PLAN_SUCCESS, data)
            alert('プランが変更されました。')
        } catch (e) {
            context.commit(ActionTypes.SWITCH_PLAN_FAILTURE)
        }
    },
    [ActionTypes.CANCEL_PLAN_REQUEST]: async (
        context: ActionContext<CustomerState, RootState>,
        payload: { plan_name: string }
    ): Promise<Boolean> => {
        try {
            await Axios.post(STORE_API_URL + STORE_REST_CANCEL, JSON.stringify(payload), {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + context.rootState.authentication.token,
                },
            })
            context.commit(ActionTypes.CANCEL_PLAN_SUCCESS)
            return true
        } catch (e) {
            context.commit(ActionTypes.CANCEL_PLAN_FAILURE)
            return false
        }
    },
    [ActionTypes.GET_ORDER_HISTORY_REQUEST]: async (
        context: ActionContext<CustomerState, RootState>
    ): Promise<Boolean> => {
        try {
            const res = await fetch(STORE_API_URL + STORE_ORDER_HISTORY, {
                headers: { Authorization: 'Bearer ' + context.rootState.authentication.token },
            })
            const orderHistory = await res.json()
            const isAlreadySignedUp = (await orderHistory.data.length) != 0
            context.commit(ActionTypes.GET_ORDER_HISTORY_SUCCESS, {
                isAlreadySignedUp: isAlreadySignedUp,
            })
            return isAlreadySignedUp
        } catch (e) {
            context.commit(ActionTypes.GET_ORDER_HISTORY_FAILURE)
        }
        return false
    },
    [ActionTypes.SEND_SUPPORT_MAIL]: async (
        context: ActionContext<CustomerState, RootState>,
        payload: { data: object }
    ): Promise<boolean> => {
        try {
            const res = await Axios.post(STORE_API_URL + STORE_SUPPORT_MAIL, payload.data)
            if (res.status == 200) {
                return true
            }
            context.commit(authentication.SEND_MAIL_FAILURE)
            return false
        } catch (e) {
            context.commit(authentication.SEND_MAIL_FAILURE)
            return false
        }
    },
    [ActionTypes.SEND_ADMIN_MAIL]: async (
        context: ActionContext<CustomerState, RootState>,
        payload: { data: object }
    ): Promise<boolean> => {
        try {
            const res = await Axios.post(STORE_API_URL + STORE_ADMIN_MAIL, {
                headers: {
                    Authorization: 'Bearer ' + context.rootState.authentication.token,
                    'Content-Type': 'application/json',
                },
                body: payload,
            })
            if (res.status == 200) {
                return true
            }
            context.commit(authentication.SEND_MAIL_FAILURE)
            return false
        } catch (e) {
            context.commit(authentication.SEND_MAIL_FAILURE)
            return false
        }
    },
    [ActionTypes.GET_STEP_MAIL]: async (
        context: ActionContext<CustomerState, RootState>
    ): Promise<boolean> => {
        try {
            const res = await Axios.get(STORE_API_URL + STORE_ADMIN_STEP_MAIL, {
                headers: {
                    Authorization: 'Bearer ' + context.rootState.authentication.token,
                    'Content-Type': 'application/json',
                },
            })
            if (res.status == 200) {
                // eslint-disable-next-line no-console
                context.commit(ActionTypes.GET_STEP_MAIL, {
                    data: res,
                })
                return true
            }
            return false
        } catch (e) {
            return false
        }
    },
    [ActionTypes.PUT_STEP_MAIL]: async (
        context: ActionContext<CustomerState, RootState>,
        payload: { data: any }
    ): Promise<boolean> => {
        try {
            // const formData = new FormData()
            // formData.append('num', payload.data.num)
            // formData.append('time', payload.data.time)
            // formData.append('content', payload.data.content)
            // formData.append('date', payload.data.date)
            // formData.append('file', payload.data.file)
            // formData.append('old_file', payload.data.old_file)
            // formData.append('title', payload.data.title)

            const res = await Axios.put(STORE_API_URL + STORE_ADMIN_STEP_MAIL, {
                headers: {
                    Authorization: 'Bearer ' + context.rootState.authentication.token,
                    'Content-Type': 'application/json',
                },
                body: payload,
            })
            if (res.status == 200) {
                return true
            }
            return false
        } catch (e) {
            return false
        }
    },
    [ActionTypes.POST_STEP_MAIL]: async (
        context: ActionContext<CustomerState, RootState>,
        payload: { data: any }
    ): Promise<boolean> => {
        try {
            const res = await Axios.post(STORE_API_URL + STORE_ADMIN_STEP_MAIL, {
                headers: {
                    Authorization: 'Bearer ' + context.rootState.authentication.token,
                    'Content-Type': 'application/json',
                },
                body: payload,
            })
            if (res.status == 200) {
                return true
            }
            return false
        } catch (e) {
            return false
        }
    },
    [ActionTypes.DELETE_STEP_MAIL]: async (
        context: ActionContext<CustomerState, RootState>,
        payload: { data: any }
    ): Promise<boolean> => {
        try {
            const res = await Axios.delete(STORE_API_URL + STORE_ADMIN_STEP_MAIL, {
                headers: {
                    Authorization: 'Bearer ' + context.rootState.authentication.token,
                    'Content-Type': 'application/json',
                },
                data: payload,
            })
            if (res.status == 200) {
                return true
            }
            return false
        } catch (e) {
            return false
        }
    },
}
