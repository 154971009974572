
import { Vue, Component } from 'vue-property-decorator'
import { ActionTypes } from '@/store/modules/customer/types'
import OptionCheckbox from '../components/OptionCheckbox.vue'

@Component({
    components: {
        OptionCheckbox,
    },
})
export default class MyPacgeCancellationEsatJ extends Vue {
    showPopup = false
    hasPlan = false
    checkbox = [false, false, false, false, false]
    options = ['1', '2', '3', '4', '5']
    allCheck = false
    isJustOne = 0
    userdata = this.$store.state.customer.userdata
    buttonOn = false

    async created() {
        this.isJustOne = this.userdata.length

        if (this.isJustOne === 1 && this.userdata[0].plan.includes('ESAT-J')) {
            this.hasPlan = true
        } else if (this.isJustOne === 2) {
            this.hasPlan = true
        } else {
            this.hasPlan = false
        }
    }

    navigate(route: string): void {
        if (this.$router.currentRoute.path !== route) {
            this.$router.push({ path: route })
        }
    }

    isButtonEnabled() {
        if (this.checkbox.every(item => item === true)) {
            this.allCheck = true
        } else {
            this.allCheck = false
        }
        if (this.allCheck === true && this.hasPlan) {
            this.buttonOn = true
        } else {
            this.buttonOn = false
        }
    }
    async onCancelClicked(): Promise<void> {
        this.$store.commit(ActionTypes.START_PAYMENT)
        let isSuccess = await this.$store.dispatch(ActionTypes.CANCEL_PLAN_REQUEST, {
            plan_name: 'esat',
        })
        this.showPopup = false
        if (isSuccess) {
            this.$router.push('/feedback/esatj')
            await this.$store.dispatch(ActionTypes.GET_USERDATA)
        }
    }
}
