
import { Vue, Component } from 'vue-property-decorator'
import { ActionTypes } from '../../store/modules/authentication/types'
import RegistrationProgress from '../../components/RegistrationProgress.vue'
import PasswordField from '../../components/PasswordField.vue'
import i18n from '@/plugins/i18n'
import DatePicker from '../../components/DatePicker.vue'



@Component({
    components: {
        PasswordField,
        RegistrationProgress,
        DatePicker,
    },
})
export default class AddAccount extends Vue {
    snackbar = false
    valid = false
    lastName = ''
    firstName = ''
    email = ''
    password = ''
    birthday: string = ''
    checkbirth = false
    requiredRules = [(v: string) => !!v || i18n.t('thisFieldIsRequired')]
    // eslint-disable-next-line no-useless-escape
    RfcEmailPattern = /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*|"([\w!#$%&'*+\-\/=?^`{|}~. ()<>\[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d\-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/

    isTestAccount: boolean = true
    accountType: string = 'test'



    emailRules = [
        (v: string) => !!v || i18n.t('thisEmailFieldIsRequired'),
        (v: string) => this.RfcEmailPattern.test(v) || i18n.t('invalidEmail'),
    ]
    updateAccountType() {
        this.accountType = this.isTestAccount ? 'test' : 'live'
    }
    goTo(url: string): void {
        window.open(url, '_blank')
    }
    birthdayUpdate(value: any): void {
        this.birthday = value
        this.checkbirthday()
    }
    checkbirthday() {
        if (this.birthday.length >= 6) {
            this.checkbirth = true
        }
    }
    mkelement() {
        document.getElementById('signup-btn')?.addEventListener('click', function(event) {
            event.preventDefault()
        })

        const g = document.createElement('div')
        g.setAttribute('id', 'test')
        const box = document.getElementById('signup-btn')
        box?.appendChild(g)

        let script = document.createElement('script')
        script.src = 'https://r.moshimo.com/af/r/result.js?p_id=4186&pc_id=10618&m_v=signup'
        script.id = 'msmaf'

        let noscript = document.createElement('noscript')
        let img = document.createElement('img')
        img.src = 'https://r.moshimo.com/af/r/result?p_id=4186&pc_id=10618&m_v=signup'
        img.setAttribute('width', '1')
        img.setAttribute('height', '1')
        img.setAttribute('alt', '')
        noscript.appendChild(img)

        document.querySelector('.ad')?.appendChild(script)
        document.querySelector('.ad')?.appendChild(noscript)
    }
    async submit(): Promise<void> {
        document.getElementById('signup-btn')?.addEventListener('click', function(event) {
            event.preventDefault()
        })
        
        if ((this.$refs['form'] as HTMLFormElement).validate()) {
            const res = await this.$store.dispatch(ActionTypes.MAKE_TEST_ACCOUNT, {
                email: this.email,
                password: this.password,
                firstName: this.firstName,
                lastName: this.lastName,
                birthday: this.birthday,
                stage : this.accountType,
            })
            if (res) {
                alert('アカウントの作成が完了しました')
            }
        }
    }
}
