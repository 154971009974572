
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class MyPageUserInfo extends Vue {
    userdata = this.$store.state.customer.userdata
    userName: string = '-'
    userEmail: string = '-'
    data_num = 0
    kiminiId: string = '-'

    async created() {
        console.log(this.userdata)
        if (!this.userdata) {
            // If the user data is empty, refresh the page once. If it is still empty, do nothing.
            const hasRefreshed = localStorage.getItem('hasRefreshed')

            if (!this.userdata && !hasRefreshed) {
                localStorage.setItem('hasRefreshed', 'true')
                location.reload()
            }
        }
        if (!this.userdata[0]) {
            this.userdata[0] = this.userdata
        }

        this.data_num = this.userdata?.length
        if (this.userdata.length !== 0) {
            this.userName = this.userdata[0].last_name + ' ' + this.userdata[0].first_name
            this.userEmail = this.userdata[0].email
        }
    }

    get planName() {
        if (this.data_num === 1 && !this.userdata[0].plan.includes('ESAT-J')) {
            this.kiminiId = this.userdata[0].kimini.id
            return this.userdata[0].plan
        } else if (this.data_num === 2) {
            if (this.userdata[0].plan.includes('ESAT-J')) {
                this.kiminiId = this.userdata[1].kimini.id
                return this.userdata[1].plan
            } else {
                this.kiminiId = this.userdata[0].kimini.id
                return this.userdata[0].plan
            }
        } else {
            if (this.data_num !== 0) {
                this.kiminiId = this.userdata[0].kimini.id
                return this.userdata[0].plan
            } else {
                return '-'
            }
        }
    }

    get nextPayAt() {
        let timestamp = 0
        if (this.data_num === 1 && !this.userdata[0].plan.includes('ESAT-J')) {
            timestamp = this.userdata[0].next_pay_at
        } else if (this.data_num === 2) {
            if (this.userdata[0].plan.includes('ESAT-J')) {
                timestamp = this.userdata[1].next_pay_at
            } else {
                timestamp = this.userdata[0].next_pay_at
            }
        }

        if (timestamp !== 0) {
            return new Date(timestamp * 1000).toLocaleDateString()
        } else if (this.data_num !== 0) {
            this.kiminiId = this.userdata[0].kimini.id
            return this.userdata[0].plan
        } else {
            return '-'
        }
    }
}
