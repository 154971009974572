
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import ChoosePlanTab from '../components/ChoosePlanTab.vue'
import ChoosePlanPrice from '../components/ChoosePlanPrice.vue'
import { ActionTypes } from '../store/modules/customer/types'
import * as PlanUtils from '../plugins/planUtils'
import PackageContentOriginal from '../components/PackageContentOriginal.vue'
import PackageContentKids from '../components/PackageContentKids.vue'
import PackageContentFamily from '../components/PackageContentFamily.vue'
import PackageContentAdult from '../components/PackageContentAdult.vue'
import EventBanner from '../components/EventBanner.vue'
import ChooseMultiPlanTabKids from './ChooseMultiPlanTab.vue'
import ChooseCategory from './ChooseCategory.vue'
import ChoosePlanEsatJPrice from './ChoosePlanEsatJPrice.vue'
import PackageContentEsatJ from './PackageContentEsatJ.vue'
import store from '../store'

@Component({
    components: {
        ChoosePlanTab,
        ChoosePlanPrice,
        PackageContentOriginal,
        PackageContentKids,
        PackageContentFamily,
        PackageContentAdult,
        EventBanner,
        ChooseMultiPlanTabKids,
        ChooseCategory,
        ChoosePlanEsatJPrice,
        PackageContentEsatJ,
    },
})
export default class ChoosePlan extends Vue {
    @Prop({ default: 'Kodomo' }) package!: string

    @Prop() isBeforeSignUp?: boolean
    @Prop() isEsatJ?: boolean
    @Prop() isRegisteraion?: boolean
    @Prop() isPriceBefore?: boolean
    packages: string[] = ['original', 'kids', 'family', 'adult']
    subscriptionTypes: string[] = ['monthly', 'threeMonths', 'twelveMonths']
    subscriptionTypesStandard: string[] = ['threeMonths', 'twelveMonths']
    category: string[] = ['Kodomo', 'Otona', 'Musio ESAT-J']
    KodomoType: string[] = ['original', 'kids', 'family']
    OtonaType: string[] = ['adult']
    EsatJType: string[] = ['standard', 'ipad']
    frequencies: string[] = [
        'fourLessonsPerMonth',
        'eightLessonsPerMonth',
        'thirtyLessonsPerMonth',
        'sixtyLessonsPerMonth',
    ]
    familyPackageContents: string[] = [
        'familyPackageContentEdgeHawaii',
        'familyPackageContentEdgeBusiness',
    ]
    adultPackageContents: string[] = [
        'adultPackageContentEdgeHawaii',
        'adultPackageContentEdgeBusiness',
        // 'adultPackageContentEdgeLiving',
    ]
    adultPlanSelect: string[] = ['adultSinglePlan', 'adultAllPlan']
    subscriptionTypesOriginalMaster: string[] = ['monthly', 'threeMonths', 'twelveMonths']
    subscriptionTypesKidFamilyMaster: string[] = ['twelveMonths']
    subscriptionTypesAdultMaster: string[] = ['threeMonths', 'twelveMonths']
    frequenciesOriginalKidsMaster: string[] = [
        'fourLessonsPerMonth',
        'eightLessonsPerMonth',
        'thirtyLessonsPerMonth',
        'sixtyLessonsPerMonth',
    ]
    frequenciesAdultMaster: string[] = [
        'fourLessonsPerMonth',
        'eightLessonsPerMonth',
        'thirtyLessonsPerMonth',
        'sixtyLessonsPerMonth',
    ]
    frequenciesFamilyMaster: string[] = ['thirtyLessonsPerMonth', 'sixtyLessonsPerMonth']
    safetyPlan: string[] = ['加入する', '加入しない']
    tabCategory: string = this.category[0]
    tabPackages: string = this.KodomoType[0] // package
    tabSubscriptionTypes: string = this.subscriptionTypes[0]
    tabSelectPlanTypes: string = this.adultPlanSelect[0]
    tabFrequencies: string = this.frequencies[0]
    tabFamilyPackageContents: string = this.familyPackageContents[0]
    tabAdultPackageContents: string = this.adultPackageContents[0]
    tabSafetyPlan: string = this.safetyPlan[0]
    selectedOptions: { [s: string]: any }[] = []
    selectedOption: { [s: string]: any } = {}
    selectOptionBook: string = '選択しない'
    packageOriginalUrlWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_original_web.png'
    // 'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/50_campaigen_musio.png'
    packageKidsUrlWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_kids_web_new.png'
    packageFamilyUrlWeb =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/package_family_web.png'
    packageAdultHawaiUrlWeb =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/adultHawai.png'
    packageAdultBusinessUrlWeb =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/adultBusiness.png'
    registrationOriginal =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/registration_original.png'
    registrationKids =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/registration_kids.png'
    registrationFamily =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/registration_family.png'
    registrationAdult =
        'https://usermodel-assets.s3-ap-northeast-1.amazonaws.com/musio-english-assets/registration_adult.png'
    registrationEsatJ =
        'https://usermodel-assets.s3.ap-northeast-1.amazonaws.com/musio-english-assets/ESAT-J/MEJ+set+1.png'

    selectedTab: string = 'Option 1'

    get selectedTabColor() {
        if (this.tabCategory === 'Kodomo') {
            return {
                color: '#e20707',
                background: '#FFDDDD',
            }
        } else if (this.tabCategory === 'Otona') {
            return {
                color: '#087426',
                background: '#E2FFDD',
            }
        } else if (this.tabCategory === 'Musio ESAT-J') {
            return {
                color: '#7747ff',
                background: '#e9ebff',
            }
        } else {
            return {
                // Default: original
                color: '#e20707',
                background: '#FFDDDD',
            }
        }
    }

    get price() {
        if (
            this.selectedOption.length !== 0 &&
            this.selectedOption[0] != null &&
            this.selectedOption[0].price != null
        ) {
            return this.selectedOption[0].price
        } else {
            return 0
        }
    }
    get originalPrice() {
        if (
            this.selectedOption.length !== 0 &&
            this.selectedOption[0] != null &&
            this.selectedOption[0].original_price != null
        ) {
            return this.selectedOption[0].original_price
        } else {
            return 0
        }
    }

    get optionPrice() {
        if (
            this.selectedOption.length !== 0 &&
            this.selectedOption[0] != null &&
            this.selectedOption[0].original_price != null
        ) {
            return this.selectedOption[0].option_price
        } else {
            return 0
        }
    }

    get preDiscountPrice() {
        if (this.selectedOption.length !== 0) {
            return this.selectedOption[0]?.pre_discount_price ?? 0
        } else {
            return 0
        }
    }
    get discountRate() {
        if (
            this.selectedOption.length !== 0 &&
            this.selectedOption[0] != null &&
            this.selectedOption[0].discount_rate != null
        ) {
            return this.selectedOption[0].discount_rate
        } else {
            return 0
        }
    }
    get priceChange() {
        if (
            this.selectedOption.length !== 0 &&
            this.selectedOption[0] != null &&
            this.selectedOption[0].price_change != null
        ) {
            return this.selectedOption[0].price_change
        } else {
            return 0
        }
    }

    get contractMonth() {
        if (
            this.selectedOption.length !== 0 &&
            this.selectedOption[0] != null &&
            this.selectedOption[0].contract_month != null
        ) {
            return this.selectedOption[0].contract_month
        } else {
            return 0
        }
    }
    get optionId() {
        if (
            this.selectedOption.length !== 0 &&
            this.selectedOption[0] != null &&
            this.selectedOption[0].id != null
        ) {
            return this.selectedOption[0].id
        } else {
            return ''
        }
    }
    // Depend on the selected "package", change "tab" contents
    //확인해보기
    packageChanged(): void {
        this.subscriptionTypes.splice(-this.subscriptionTypes.length)
        this.frequencies.splice(-this.frequencies.length)

        if (this.tabPackages === 'family') {
            Object.assign(this.subscriptionTypes, this.subscriptionTypesKidFamilyMaster)
            Object.assign(this.frequencies, this.frequenciesFamilyMaster)
        } else if (this.tabPackages === 'kids') {
            Object.assign(this.subscriptionTypes, this.subscriptionTypesKidFamilyMaster)
            Object.assign(this.frequencies, this.frequenciesOriginalKidsMaster)
        } else if (this.tabPackages == 'adult') {
            Object.assign(this.subscriptionTypes, this.subscriptionTypesAdultMaster)
            Object.assign(this.frequencies, this.frequenciesAdultMaster)
        } else {
            Object.assign(this.subscriptionTypes, this.subscriptionTypesOriginalMaster)
            Object.assign(this.frequencies, this.frequenciesOriginalKidsMaster)
        }
    }
    getResult(): void {
        if (this.tabCategory === 'Kodomo') {
            let optionId: string = PlanUtils.getOptionId(
                this.tabPackages,
                this.tabSubscriptionTypes,
                this.tabFamilyPackageContents,
                this.tabAdultPackageContents,
                this.tabFrequencies
            )
            let productId: string = PlanUtils.getProductId(
                this.tabFrequencies,
                this.tabPackages,
                this.tabAdultPackageContents
            )
            this.selectedOption = this.selectedOptions.filter((plan: any) => {
                return plan.id === optionId && plan.product_id === productId
            })
            if (this.selectOptionBook !== null) {
                this.selectedOption = this.selectedOption.filter((plan: any) => {
                    return plan.textbook === this.selectOptionBook
                })
            }
        } else if (this.tabCategory === 'Otona') {
            let optionId: string = PlanUtils.getOptionId(
                this.tabPackages,
                this.tabSubscriptionTypes,
                this.tabFamilyPackageContents,
                this.tabAdultPackageContents,
                this.tabFrequencies
            )
            let productId: string = PlanUtils.getProductId(
                this.tabFrequencies,
                this.tabPackages,
                this.tabAdultPackageContents
            )
            this.selectOptionBook = '選択しない'
            this.selectedOption = this.selectedOptions.filter((plan: any) => {
                return plan.id === optionId && plan.product_id === productId
            })
            if (this.selectOptionBook !== null) {
                this.selectedOption = this.selectedOption.filter((plan: any) => {
                    return plan.textbook === this.selectOptionBook
                })
            }
        } else {
            let optionId: string | undefined = PlanUtils.getOptionIdEsatJ(
                this.tabPackages,
                this.tabSubscriptionTypes,
                this.tabSafetyPlan
            )
            this.selectedOption = this.selectedOptions.filter(plan => {
                return plan.id === optionId
            })
        }
    }
    // Initialize
    async created(): Promise<void> {
        // get product list from DB
        await this.$store.dispatch(ActionTypes.GET_PRODUCTS)
        if (this.$store.state.customer.products.length != 0) {
            this.selectedOptions = []
            let result = []
            for (let temp_product of this.$store.state.customer.products) {
                if (temp_product.option != null) {
                    for (let temp_option of temp_product.option.items) {
                        temp_option.original_price = temp_product.price
                        temp_option.contract_month = PlanUtils.getSubscriptionTypeNumber(
                            temp_option.id
                        )
                        temp_option.product_id = temp_product.id
                        result.push(temp_option)
                    }
                }
            }
            this.selectedOptions = result
        }

        if (this.tabCategory !== this.package) {
            this.tabCategory = this.package
        }

        if (this.$route.params.package !== undefined) {
            if (this.$route.params.package === 'esatj') {
                this.tabCategory = this.category[2]
            } else if (this.$route.params.package === 'kodomo') {
                this.tabCategory = this.category[0]
            } else {
                this.tabCategory = this.category[1]
            }
        }

        // If selected product is saved in local storage
        else if (
            this.$store.state.customer.selectedProduct &&
            this.$store.state.customer.selectedProduct.contract_month
        ) {
            let selectedProduct = this.$store.state.customer.selectedProduct
            // Saved selected product(in local storage) is same as the package linked from LP

            if (this.tabCategory === 'Kodomo') {
                if (selectedProduct.packageName === 'original') {
                    this.tabPackages = this.KodomoType[0]

                    if (selectedProduct.contract_month === 1) {
                        this.tabSubscriptionTypes = this.subscriptionTypes[0]
                    } else if (selectedProduct.contract_month === 3) {
                        this.tabSubscriptionTypes = this.subscriptionTypes[1]
                    } else {
                        this.tabSubscriptionTypes = this.subscriptionTypes[2]
                    }

                    if (selectedProduct.name.includes('4')) {
                        this.tabFrequencies = this.frequencies[0]
                    } else if (selectedProduct.name.includes('8')) {
                        this.tabFrequencies = this.frequencies[1]
                    } else if (selectedProduct.name.includes('30')) {
                        this.tabFrequencies = this.frequencies[2]
                    } else {
                        this.tabFrequencies = this.frequencies[3]
                    }
                } else if (selectedProduct.packageName === 'kids') {
                    this.tabPackages = this.KodomoType[1]

                    if (selectedProduct.name.includes('4')) {
                        this.tabFrequencies = this.frequenciesOriginalKidsMaster[0]
                    } else if (selectedProduct.name.includes('8')) {
                        this.tabFrequencies = this.frequenciesOriginalKidsMaster[1]
                    } else if (selectedProduct.name.includes('30')) {
                        this.tabFrequencies = this.frequenciesOriginalKidsMaster[2]
                    } else {
                        this.tabFrequencies = this.frequenciesOriginalKidsMaster[3]
                    }
                } else {
                    this.tabPackages = this.KodomoType[2]

                    if (selectedProduct.name.includes('30')) {
                        this.tabFrequencies = this.frequencies[0]
                    } else {
                        this.tabFrequencies = this.frequencies[1]
                    }
                }
            } else if (this.tabCategory === 'Otona') {
                if (selectedProduct.id.includes('hawaii')) {
                    this.tabAdultPackageContents = this.adultPackageContents[0]
                } else {
                    this.tabAdultPackageContents = this.adultPackageContents[1]
                }
            } else {
                if (selectedProduct.packageName === 'standard') {
                    this.tabPackages = this.EsatJType[0]
                } else {
                    this.tabPackages = this.EsatJType[1]
                }
            }
            this.tabFamilyPackageContents = this.familyPackageContents[0]
        }

        this.packageChanged()

        this.getResult()
    }

    // on click "Signup"
    onSignupClicked() {
        // Save selected product to local storage
        let selectedProduct = this.selectedOption[0]
        selectedProduct.packageName = this.tabPackages
        selectedProduct.subscriptionType = this.tabSubscriptionTypes
        selectedProduct.frequency = this.tabFrequencies
        if (selectedProduct.packageName === 'family') {
            selectedProduct.familyPackageContent = this.tabFamilyPackageContents
        } else {
            selectedProduct.familyPackageContent = ''
        }
        selectedProduct.productId = PlanUtils.getProductId(
            this.tabFrequencies,
            this.tabPackages,
            this.tabAdultPackageContents
        )
        selectedProduct.optionId = PlanUtils.getOptionId(
            this.tabPackages,
            this.tabSubscriptionTypes,
            this.tabFamilyPackageContents,
            this.tabAdultPackageContents,
            this.tabFrequencies
        )

        this.$store.commit(ActionTypes.SAVE_OPTIONS, { product: selectedProduct })
        if (store.getters.isAuthenticated) {
            this.$router.push('/registration')
        } else {
            this.$router.push('/signup')
        }
    }

    // Emit selectedProduct information to parent component
    @Watch('selectedOption')
    @Emit('returnValue')
    selectedOptionChanged() {
        return this.selectedOption[0]
    }
    categoryValueChanged(tabValue: string): void {
        this.tabCategory = tabValue
        if (this.tabCategory === 'Kodomo') {
            this.tabPackages = this.KodomoType[0]
        } else if (this.tabCategory === 'Otona') {
            this.tabPackages = this.OtonaType[0]
        } else {
            this.tabPackages = this.EsatJType[0]
        }
        this.packageChanged()
        this.getResult()
    }
    // Receive "Emit" from child component : package
    packageValueChanged(tabValue: string): void {
        this.tabPackages = tabValue
        this.packageChanged()
        if (this.tabPackages === 'Kodomo') {
            this.selectOptionBook = '選択しない'
        } else {
            this.selectedOption[0] = null
        }
        this.getResult()
    }
    // Receive "Emit" from child component : subscriptionType
    subscriptionTypeValueChanged(tabValue: string): void {
        this.tabSubscriptionTypes = tabValue
        this.getResult()
    }
    // Receive "Emit" from child component : frequency
    frequencyValueChanged(tabValue: string): void {
        this.tabFrequencies = tabValue
        this.getResult()
    }
    // Receive "Emit" from child component : familyPackageContents
    familyPackageContentsValueChanged(tabValue: string): void {
        this.tabFamilyPackageContents = tabValue
        this.getResult()
    }
    // Receive "Emit" from child component : familyPackageContents
    adultPackageContentsValueChanged(tabValue: string): void {
        this.tabAdultPackageContents = tabValue
        this.getResult()
    }
    handleTabChange(tabValue: string): void {
        this.selectedTab = tabValue
    }
    returnObtionBook(tabValue: string): void {
        this.selectOptionBook = tabValue
        this.getResult()
    }
    returnSafetyPlan(tabValue: string): void {
        this.tabSafetyPlan = tabValue
        this.getResult()
    }
    newEvent = false
}
