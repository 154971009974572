
import { Vue, Component } from 'vue-property-decorator'
import { ActionTypes } from '@/store/modules/customer/types'

@Component
export default class MyPageCancellation extends Vue {
    showPopup = false
    hasPlan = false

    async created() {
        if (
            this.$store.state.customer.userdata.length === 1 &&
            !this.$store.state.customer.userdata[0].plan.includes('ESAT-J')
        ) {
            this.hasPlan = true
        } else if (this.$store.state.customer.userdata.length === 2) {
            this.hasPlan = true
        } else {
            this.hasPlan = false
        }
    }

    async onCancelClicked(): Promise<void> {
        this.$store.commit(ActionTypes.START_PAYMENT)
        let isSuccess = await this.$store.dispatch(ActionTypes.CANCEL_PLAN_REQUEST, {
            plan_name: 'kimini',
        })
        this.showPopup = false
        if (isSuccess) {
            this.$router.push('/feedback')
            await this.$store.dispatch(ActionTypes.GET_USERDATA)
        }
    }
}
